import React, { useState } from 'react';
import { Button, Text, Spacer, InputField, SvgIcon } from 'ui';
import { validationSchema } from './ForgotPasswordForm.schema';
import { useTranslations } from '~/services/cms';
import { Formik, Field, FieldProps } from 'formik';
import { Mode } from '../../types';

import styles from './ForgotPasswordForm.module.css';
import { requestForgotPassword } from '../../requests/forgotPassword';
import { useMarkets } from '~/services/globalization';
import { useNotification } from '~/shared/hooks/useNotification';

type ForgotPasswordProps = {
    csrfToken?: string;
    changeMode: (mode: Mode) => void;
}

export const ForgotPasswordForm = ({ csrfToken, changeMode }: ForgotPasswordProps) => {
    const translate = useTranslations();
    const { push } = useNotification();
    const schema = validationSchema();
    const [error, setError] = useState<string | null>(null);
    const { currentMarket } = useMarkets();

    return (
        <>
            <div className={styles.forgotHeader}>
                <div className={styles.iconSize}>
                    <SvgIcon name='key' aria-hidden="true" />
                </div>
                <Text children={translate('forgotPassword.title')} variant="display4" />
            </div>
            <Spacer size="4" />
            <Text children={translate('forgotPassword.description')} variant="body" />
            <Spacer size="5" />

            <Formik
                initialValues={{ email: '', csrfToken: csrfToken }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    if (!values?.csrfToken) {
                        return null
                    }

                    const response = await requestForgotPassword({
                        email: values.email,
                        csrfToken: values.csrfToken,
                        market: currentMarket?.storeKey
                    });

                    if (response.status >= 500) {
                        setError(translate('forgotPassword.error'));
                    } else if (response.status >= 300) {
                        setError(response.statusText);
                    } else {
                        setError(null);
                        setSubmitting(false);
                        changeMode('login');

                        push({
                            severity: 'success',
                            text: translate('forgotPassword.success'),
                            duration: 8000
                        });

                    }
                }}
                enableReinitialize={true}
            >
                {(formik) => (
                    <form className={styles.forgotForm} onSubmit={formik.handleSubmit}>
                        <Field name="csrfToken" type="hidden" />
                        <Field name="email">
                            {({
                                field,
                                meta,
                            }: FieldProps) => (
                                <InputField
                                    {...field}
                                    placeholder={translate('auth.email')}
                                    invalidMessage={meta.touched && meta.error ?
                                        meta.error.charAt(0).toUpperCase() + meta.error.slice(1) : undefined}
                                    isActive={field.value && field.value.length > 0 ? true : false}
                                    label={translate('auth.email')} />
                            )}
                        </Field>
                        <Spacer size="4" />
                        {error && <Text color='errorColor' variant='body'>{error}</Text>}
                        <Spacer size="2" />
                        <div className={styles.loginLink}>
                            <Button type="button" variant='link' size='large' onClick={() => changeMode('login')}>{translate('forgotPassword.returnLogin')}</Button>
                        </div>
                        <Spacer size="2" />
                        <Button type="submit" variant='primary' size='large' isFlexible>{translate('forgotPassword.confirm')}</Button>
                    </form>)}
            </Formik>
        </>
    );
};
