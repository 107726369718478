import React, { useEffect, useState } from 'react';
import { ModuleContainer } from 'ui';
import { getCsrfToken } from 'next-auth/react';
import { LoginForm, ForgotPasswordForm } from './components';
import { DynamicBlocks } from '~/dynamic-blocks';
import { usePage } from '~/services/cms';

import styles from './P180LoginPage.module.css';
import { Mode } from './types';

export const P180LoginPage = () => {
    const [csrfToken, setCsrfToken] = useState<string | undefined>(undefined);
    const [mode, setMode] = useState<Mode>('login');
    const { data: page } = usePage();

    useEffect(() => {
        getCsrfToken().then((token) => {
            setCsrfToken(token);
        });
    }, []);

    const changeMode = (mode: Mode) => {
        setMode(mode);
    }

    return (
        <>
            <ModuleContainer hasGutter>
                <div className={styles.p180container}>
                    {mode === 'login' && <LoginForm csrfToken={csrfToken} changeMode={changeMode} />}
                    {mode === 'recover' && <ForgotPasswordForm csrfToken={csrfToken} changeMode={changeMode} />}
                </div>
            </ModuleContainer>
            <DynamicBlocks elements={page?.pageElements} />;
        </>
    );
};
