type IProps = {
    market?: string;
    csrfToken: string;
    email: string;
}

export async function requestForgotPassword({ market, email, csrfToken }: IProps) {
    const prefixUrl = new URL('/api/auth/proxy', window.location.origin);
    const response = await fetch(`${prefixUrl}/${market}/Auth/forgotPassword/${email}`, {
        method: 'POST',
        body: JSON.stringify({ csrfToken }),
        headers: { 'Content-Type': 'application/json' },
    });

    const { status, statusText } = response;
    return { status, statusText };
}
