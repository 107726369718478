import * as yup from 'yup';

export const validationSchema = () =>
    yup
        .object({
            csrfToken: yup.string().required(),
            email: yup.string().email().required(),
            password: yup.string().required()
        })
        .required();
