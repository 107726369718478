import React, { useState } from 'react';
import { Button, Text, Spacer, InputField, SvgIcon } from 'ui';
import { signIn } from 'next-auth/react';
import { validationSchema } from './Login.schema';
import { useTranslations } from '~/services/cms';
import { Formik, Field, FieldProps } from 'formik';
import { Mode } from '../../types';
import { useBasket } from '~/components/features/Basket';

import styles from './LoginForm.module.css';
import { useRouter } from 'next/router';
import { useMarkets } from '~/services/globalization';
import { useSessionStorage } from 'react-use';
import { Fields } from '$features/Checkout/models';

type LoginProps = {
    csrfToken?: string;
    changeMode: (mode: Mode) => void;
}

export const LoginForm = ({ csrfToken, /*changeMode*/ }: LoginProps) => {
    const translate = useTranslations();
    const router = useRouter();
    const schema = validationSchema();
    const { currentMarket } = useMarkets();
    const { deleteBasket } = useBasket();

    const [, setShippingFormFields] = useSessionStorage<Fields | null>('shippingFormFields', {});
    const [, setFormFields] = useSessionStorage<Fields | null>('formFields', {});
    const [, setPaymentFormFields] = useSessionStorage<Fields | null>('paymentFormFields', {});
    const [, setBasketFields] = useSessionStorage<Fields | null>('basketFields', {});
    const [, setInvoiceFields] = useSessionStorage<Fields | null>('invoiceFields', {});

    const [error, setError] = useState<string | null>(null);

    return (
        <>
            <div className={styles.loginHeader}>
                <div className={styles.iconSize}>
                    <SvgIcon name='user' aria-hidden="true" />
                </div>
                <Text children={translate('auth.login')} variant="display4" />
            </div>
            <Spacer size="4" />
            <Text children={translate('auth.loginDescription')} variant="body" />
            <Spacer size="5" />

            <Formik
                initialValues={{ email: '', password: '', csrfToken: csrfToken }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    const response = await signIn('credentials', {
                        redirect: false,
                        email: values.email,
                        password: values.password,
                        callbackUrl: `${window.location.origin}`,
                        market: currentMarket?.storeKey
                    });

                    if (response?.error && response.error === 'CredentialsSignin') {
                        setError(translate('auth.credentialsError'));
                    } else if (response?.error) {
                        setError(response.error);
                    } else {
                        setError(null);
                    }
                    if (response?.url) {
                        await deleteBasket()
                        setShippingFormFields({});
                        setFormFields({});
                        setPaymentFormFields({});
                        setBasketFields({});
                        setInvoiceFields({});
                        window.location.href = router?.query?.callback ? router.query.callback as string : `/${router.locale}`;
                        //router.push(router?.query?.callback ? router.query.callback as string : `/${router.locale}`);
                    }
                    setSubmitting(false);
                }}
                enableReinitialize={true}
            >
                {(formik) => (
                    <form className={styles.loginForm} onSubmit={formik.handleSubmit}>
                        <Field name="csrfToken" type="hidden" />
                        <Field name="email">
                            {({
                                field,
                                meta,
                            }: FieldProps) => (
                                <InputField
                                    {...field}
                                    placeholder={translate('auth.email')}
                                    invalidMessage={meta.touched && meta.error ?
                                        meta.error.charAt(0).toUpperCase() + meta.error.slice(1) : undefined}
                                    isActive={field.value && field.value.length > 0 ? true : false}
                                    label={translate('auth.email')} />
                            )}
                        </Field>

                        <Spacer size="3" />

                        <Field name="password">
                            {({
                                field,
                                meta,
                            }: FieldProps) => (
                                <InputField
                                    {...field}
                                    placeholder={translate('auth.password')}
                                    invalidMessage={meta.touched && meta.error ?
                                        meta.error.charAt(0).toUpperCase() + meta.error.slice(1) : undefined}
                                    isActive={field.value && field.value.length > 0 ? true : false}
                                    label={translate('auth.password')}
                                    type='password' />
                            )}
                        </Field>
                        <Spacer size="4" />
                        {error && <Text color='errorColor' variant='body'>{error}</Text>}
                        <Spacer size="1" />
                        {/*<div className={styles.forgotPasswordLink}>
                            <Button type="button" variant='link' size='large' onClick={() => changeMode('recover')}>{translate('forgotPassword.link')}</Button>
                                    </div>*/}
                        <Spacer size="1" />
                        <Button type="submit" variant='primary' size='large' isFlexible isLoading={formik.isSubmitting}>{translate('auth.loginButton')}</Button>
                    </form>)}
            </Formik>
        </>
    );
};
